@import "bootstrap/bootstrap";

@btn-font-weight: 300;
@font-family-sans-serif: "Glegoo", Helvetica, Arial, sans-serif;

body, label, .checkbox label {
	font-weight: 300;
}

body {
    padding-top: 80px;
}

.input-group-btn select { 
   -webkit-appearance: none;
   -moz-appearance:    none;
   appearance:         none;   
   -webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */    
   -moz-border-radius: 0;  /* Firefox 1-3.6 */     
   border-radius: 0;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}

.page-header h1 {
    margin-top: 0;
}